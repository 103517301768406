import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { SimpleContentPage } from '@mangoart/gatsby-ui/components/ezagrar/SimpleContentPage';
import DefaultLayout from '../layout/DefaultLayout';

const ImpressumPage = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <SimpleContentPage {...content} />
    </DefaultLayout>
  );
};

export default ImpressumPage;

export const ImpressumPageQuery = graphql`
  query ImpressumPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageImpressum {
      metadata {
        title
        metakeywords
        metadescription
      }
      content {
        title
        teaser
        text
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
  }
`;
